@font-face {
  font-family: "Gotham";
  src: url("assets/webfonts/GothamSSm-XLight_Web.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("assets/webfonts/GothamSSm-XLightItalic_Web.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("assets/webfonts/GothamSSm-Light_Web.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("assets/webfonts/GothamSSm-Book_Web.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("assets/webfonts/GothamSSm-Medium_Web.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("assets/webfonts/GothamSSm-Bold_Web.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Trim";
  src: url("assets/webfonts/Trim-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Trim";
  src: url("assets/webfonts/Trim-ExtraBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Trim";
  src: url("assets/webfonts/TrimPoster-Fat.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Trim";
  src: url("assets/webfonts/TrimPoster-Hefty.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Foundry";
  src: url("assets/webfonts/FoundryGridnik-light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ITCAvantGarde";
  src: url("assets/webfonts/ITCAvantGarde-demi.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITCAvantGarde";
  src: url("assets/webfonts/ITCAvantGarde-book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid";
  src: url("assets/webfonts/EuclidFlex-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Euclid";
  src: url("assets/webfonts/EuclidFlex-Regular.woff2") format("woff2"), url("assets/webfonts/EuclidFlex-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Univers-Bold";
  src: url("assets/webfonts/Univers-CondensedBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("assets/webfonts/HelveticaNeue-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("assets/webfonts/DIN-BlackItalic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Din";
  src: url("assets/webfonts/DINPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("assets/webfonts/DINPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Velo";
  src: url("assets/webfonts/VELOSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Velo";
  src: url("assets/webfonts/VELOSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Velo";
  src: url("assets/webfonts/VELOSans-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Mont";
  src: url("assets/webfonts/Mont-DEMOHeavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Mont";
  src: url("assets/webfonts/Mont-Bold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Mont";
  src: url("assets/webfonts/Mont-SemiBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Mont";
  src: url("assets/webfonts/Mont-Regular.woff2") format("woff2"), url("assets/webfonts/Mont-Regular.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex";
  src: url("assets/webfonts/EuclidFlex-Light.otf") format("otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex";
  src: url("assets/webfonts/EuclidFlex-Ultralight.otf") format("otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex";
  src: url("assets/webfonts/EuclidFlex-Regular.woff") format("woff"), url("assets/webfonts/EuclidFlex-Regular.woff2") format("woff2"), url("assets/webfonts/EuclidFlex-Regular.otf") format("otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex";
  src: url("assets/webfonts/EuclidFlex-Medium.woff") format("woff"), url("assets/webfonts/EuclidFlex-Medium.woff2") format("woff2"), url("assets/webfonts/EuclidFlex-Medium.otf") format("otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex";
  src: url("assets/webfonts/EuclidFlex-Bold.otf") format("otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "VeloWangle";
  src: url("assets/webfonts/VeloWWangle.woff") format("woff"), url("assets/webfonts/VeloWWangle.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
.modal-module_wrapper__eVhgU {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-module_modal__1ZT8W {
  position: fixed;
  width: 820px;
  max-height: 512px;
  width: 800px;
  margin: auto;
  padding: 20px;
  z-index: 10;
  background-size: cover;
}

.modal-module_modal__1ZT8W.modal-module_warnings__3tM-W {
  max-width: 1082px;
  max-height: 850px;
  width: 1082px;
  padding: 15px 60px 40px;
}

[data-locale=us] .modal-module_infoModal__1448b {
  margin-top: 0;
}

[data-locale=us] .modal-module_videoModal__1yp1_ {
  margin-top: 20vh;
}

[data-locale=uk] .modal-module_videoModal__1yp1_ {
  height: calc(100% - 40px);
  object-fit: contain;
}

[data-locale=za] .modal-module_videoModal__1yp1_ {
  height: calc(100% - 10vh);
  object-fit: contain;
}

.modal-module_warnings__3tM-W,
.modal-module_light__3AEV6 {
  background-color: #fff;
  color: black;
}

.modal-module_dark__1dXhY {
  background-color: #040404;
  color: #fff;
  border: solid 1px white;
}
.modal-module_dark__1dXhY .modal-module_close__1vNYr {
  filter: invert(1);
}

.modal-module_image__V6AXZ {
  background-color: transparent;
  color: black;
}

.modal-module_full__nrqa8 {
  width: 100%;
  height: 100%;
  padding: 0;
  max-height: unset;
  max-width: unset;
  top: 0;
  background-color: #000;
}
.modal-module_full__nrqa8.modal-module_locale-uk__IBT2a {
  height: calc(100% - 40px);
}
.modal-module_full__nrqa8.modal-module_locale-de__K_XN9 {
  height: calc(100% - 30px);
}
.modal-module_full__nrqa8.modal-module_locale-pl__3aa48 {
  height: 90%;
}
[data-touchpoint=gtr_tablet] .modal-module_full__nrqa8 {
  height: calc(100% - 80px);
}
[data-locale=de_gtr] .modal-module_full__nrqa8 {
  height: calc(100% - 30px);
}

.modal-module_keyboardVisible__gnfcS {
  max-height: 425px;
  margin-top: -15px;
}

.modal-module_small__1Z9v4 {
  max-width: 440px;
  width: auto;
  height: auto;
}

.modal-module_medium__3CAE9 {
  max-width: 820px;
  max-height: 580px;
  padding: 60px;
}
[data-locale=us][data-theme=velo] .modal-module_medium__3CAE9 {
  padding: 57px 102px 109px 102px;
}
[data-locale=za] .modal-module_medium__3CAE9 {
  padding: 45px 105px;
  margin-top: -10vh;
}
[data-locale=za][data-theme=velo] .modal-module_medium__3CAE9 {
  padding: 45px 50px;
}
[data-locale=uk] .modal-module_medium__3CAE9 {
  margin-top: -10vh;
}

.modal-module_blackout__2M0sm {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-module_blackout__2M0sm[data-locale=uk] {
  height: calc(100% - 40px);
}
.modal-module_blackout__2M0sm[data-locale=za] {
  height: calc(100% - 10vh);
}

.modal-module_warnings__3tM-W .modal-module_closeWrapper__kYnOL {
  justify-content: flex-end;
}
.modal-module_warnings__3tM-W .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr {
  width: 50px;
  height: 50px;
  position: relative;
  left: 40px;
}

.modal-module_closeWrapper__kYnOL {
  display: flex;
  justify-content: center;
}
.modal-module_closeWrapper__kYnOL.modal-module_keyboardVisible__gnfcS {
  position: absolute;
  right: 20px;
  top: 33px;
}
.modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr {
  background-color: #000;
  color: #fff;
}
.modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr .modal-module_cross__1TGpZ {
  fill: #fff;
}
[data-locale=de] .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr, [data-locale=de_gtr] .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr, [data-locale=uk] .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr, [data-locale=za] .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr {
  width: 63px;
  height: 63px;
}
[data-locale=us][data-theme=velo] .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr {
  background-color: transparent;
  border: solid 2px #707070;
}
[data-locale=us][data-theme=velo] .modal-module_closeWrapper__kYnOL .modal-module_close__1vNYr .modal-module_cross__1TGpZ {
  fill: black;
  width: 26%;
}
.modal-module_closeWrapper__kYnOL .modal-module_closeFull__i1xLV {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10;
}
.modal-module_closeWrapper__kYnOL .modal-module_closeFull__i1xLV .modal-module_close__1vNYr {
  color: #000;
  background-color: #fff;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.5));
  position: absolute;
}
.modal-module_closeWrapper__kYnOL .modal-module_closeFull__i1xLV .modal-module_close__1vNYr .modal-module_cross__1TGpZ {
  fill: #000;
}
[data-locale=us][data-theme=vuse] .modal-module_closeWrapper__kYnOL .modal-module_closeFull__i1xLV .modal-module_close__1vNYr .modal-module_cross__1TGpZ {
  fill: white;
}

.modal-module_rightAlignCloseButton__2qIx3 {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 50px;
  right: 32px;
}
[data-locale=za][data-theme=glo] .modal-module_rightAlignCloseButton__2qIx3, [data-locale=za][data-theme=velo] .modal-module_rightAlignCloseButton__2qIx3, [data-touchpoint=gtr_tablet] .modal-module_rightAlignCloseButton__2qIx3, [data-locale=uk] .modal-module_rightAlignCloseButton__2qIx3 {
  top: 0;
  right: 0;
  height: 100px;
  width: 93px;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 10;
}
[data-locale=za][data-theme=vuse] .modal-module_rightAlignCloseButton__2qIx3 {
  top: 0;
  right: 0;
  height: 75px;
  width: 230px;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 10;
}

.modal-module_close__1vNYr {
  appearance: none;
  border: none;
  border-radius: 50%;
  z-index: 10;
  width: 40px;
  height: 40px;
}
[data-locale=za][data-theme=glo] .modal-module_close__1vNYr, [data-locale=za][data-theme=velo] .modal-module_close__1vNYr, [data-touchpoint=gtr_tablet] .modal-module_close__1vNYr, [data-locale=uk] .modal-module_close__1vNYr, [data-locale=us][data-theme=velo] .modal-module_close__1vNYr {
  width: 53px;
  height: 53px;
  position: absolute;
  left: 10px;
  top: 10px;
}

.modal-module_warnings__3tM-W .modal-module_inner__15T42 {
  text-align: left;
}

.modal-module_inner__15T42 {
  margin-top: 20px;
  text-align: center;
}
[data-touchpoint=gtr_tablet] .modal-module_inner__15T42, [data-locale=uk] .modal-module_inner__15T42, [data-locale=za] .modal-module_inner__15T42 {
  margin-top: 55px;
}

.modal-module_innerFull__ebLoX {
  height: 100%;
  width: 100%;
}

.modal-module_cross__1TGpZ {
  width: 40%;
  padding-top: 7px;
}
.Copyrights-module_copyrights__IjAYk {
  color: white;
  font-size: 9px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 16px;
  z-index: 10;
  width: 100%;
  padding: 10px 30px;
}
.Copyrights-module_copyrights__IjAYk.Copyrights-module_learn__fg1wq {
  bottom: 102px;
}

.Copyrights-module_blackCopyrights__XG74V {
  color: black;
}

[data-touchpoint=trial_tablet] .Copyrights-module_copyrights__IjAYk {
  padding: 10px 30px 112px 48px;
  width: auto;
}
/*

1. Font families
2. Default style variables

*/
.warningText-module_warnings__30td4 {
  display: none;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  z-index: 20;
  width: 100%;
  top: 3px;
  padding: 15px;
  padding-top: 2px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Univers-Bold", sans-serif;
  color: white;
}

.warningText-module_warningTextLeft__2Iu8b,
.warningText-module_warningTextRight__3tMk1 {
  display: none;
  left: 15px;
}

[data-locale=us] .warningText-module_warnings__30td4 {
  display: flex;
  width: 1920px;
}
[data-locale=us] .warningText-module_warningTextLeft__2Iu8b,
[data-locale=us] .warningText-module_warningTextRight__3tMk1 {
  display: block;
}

.warningText-module_colorOverride__urPqC {
  color: black;
}
.video-player-module_video__2iD3d {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
[data-locale=us] .video-player-module_video__2iD3d {
  height: 80%;
}
[data-locale=de_gtr] .video-player-module_video__2iD3d {
  object-fit: cover;
}
/*

1. Font families
2. Default style variables

*/
button {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.button-module_btn__1ZLky {
  display: inline-block;
  margin: 10px;
  border-radius: 40px;
  border: none;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-weight: 700;
  font-size: calc(var(--scale-factor) * 12px);
  letter-spacing: 0.12em;
  line-height: 1.77777;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
[data-touchpoint=gtr_tablet] .button-module_btn__1ZLky, [data-locale=uk] .button-module_btn__1ZLky {
  letter-spacing: 0;
  font-weight: 900;
  font-size: 20px;
}
[data-locale=za] .button-module_btn__1ZLky, [data-locale=us] .button-module_btn__1ZLky {
  letter-spacing: 0;
  font-weight: 900;
  font-size: 22px;
}
.button-module_btn__1ZLky.button-module_primary__dlJtQ {
  border: 2px solid white;
  background-color: white;
  color: black;
  transition: all ease 300ms;
}
[data-theme=velo][data-theme=uk][data-theme=pl] .button-module_btn__1ZLky.button-module_primary__dlJtQ, [data-theme=lyft] .button-module_btn__1ZLky.button-module_primary__dlJtQ {
  color: white;
  background-color: black;
}
[data-locale=us] .button-module_btn__1ZLky.button-module_primary__dlJtQ {
  font-size: 20px;
  padding: calc(var(--scale-factor) * 11px) calc(var(--scale-factor) * 12px);
  width: 100%;
}
[data-locale=us][data-theme=velo] .button-module_btn__1ZLky.button-module_primary__dlJtQ {
  padding: calc(var(--scale-factor) * 9px) calc(var(--scale-factor) * 5px);
}
.button-module_btn__1ZLky.button-module_secondary__2mB3o {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  transition: all ease 300ms;
}
.button-module_btn__1ZLky.button-module_invert__1lmJx {
  background-color: black;
  color: white;
  transition: all ease 300ms;
}
[data-theme=velo] .button-module_btn__1ZLky.button-module_invert__1lmJx, [data-theme=lyft] .button-module_btn__1ZLky.button-module_invert__1lmJx {
  color: black;
  background-color: rgb(255, 255, 255);
}
.button-module_btn__1ZLky.button-module_ghost__2b396 {
  color: white;
  background-color: transparent;
  border: solid 2px currentColor;
}
[data-touchpoint=trial_tablet] .button-module_btn__1ZLky.button-module_ghost__2b396 {
  color: inherit;
}
.button-module_btn__1ZLky.button-module_ghostBlack__Wj13V {
  color: black;
  background-color: transparent;
  border: solid rgb(0, 0, 0) 2px;
}
.button-module_btn__1ZLky.button-module_small__3rPvz {
  padding: calc(var(--scale-factor) * 5px) calc(var(--scale-factor) * 15px);
  margin: 0;
}
[data-locale=us] .button-module_btn__1ZLky.button-module_small__3rPvz {
  font-size: 16px;
}
[data-locale=us][data-theme=velo] .button-module_btn__1ZLky.button-module_small__3rPvz {
  padding: calc(var(--scale-factor) * 4px) calc(var(--scale-factor) * 24px);
}
.button-module_btn__1ZLky.button-module_normal___TFG_ {
  padding: calc(var(--scale-factor) * 8px) calc(var(--scale-factor) * 40px);
}
.button-module_btn__1ZLky.button-module_narrow__1bJMI {
  padding: calc(var(--scale-factor) * 9px) calc(var(--scale-factor) * 15px);
}
.button-module_btn__1ZLky.button-module_short__1XvBP {
  padding: calc(var(--scale-factor) * 10px) calc(var(--scale-factor) * 25px);
}
.button-module_btn__1ZLky.button-module_game__YybCF {
  min-width: 180px;
  padding: 9px 20px;
}
@media (max-width: 359px) {
  .button-module_btn__1ZLky.button-module_game__YybCF {
    min-width: 150px;
    padding: 8px 10px;
    font-size: 11px;
  }
}

.button-module_img__ULlMc {
  background-color: transparent;
  appearance: none;
  border: none;
  position: relative;
  padding: 0;
  margin: 0;
  text-transform: initial;
}
.button-module_img__ULlMc img {
  display: block;
}
.button-module_img__ULlMc:active, .button-module_img__ULlMc:focus {
  outline: none;
}

.button-module_splitButtonWrapper__3zbTR {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.button-module_splitButtonWrapper__3zbTR.button-module_bottom__2Q7BG {
  bottom: 40px;
}
.button-module_splitButtonWrapper__3zbTR.button-module_top__3jaB6 {
  top: 30px;
}

.button-module_splitButtonContainer__1m1qX {
  border-radius: 40px;
  background: linear-gradient(90deg, rgb(241, 176, 44) 0%, rgb(235, 92, 33) 100%);
  overflow: hidden;
  color: white;
}
[data-theme=vuse][data-locale=de] .button-module_splitButtonContainer__1m1qX, [data-theme=vuse][data-locale=de_gtr] .button-module_splitButtonContainer__1m1qX, [data-theme=velo] .button-module_splitButtonContainer__1m1qX, [data-theme=glo] .button-module_splitButtonContainer__1m1qX, [data-locale=uk][data-theme=velo] .button-module_splitButtonContainer__1m1qX {
  background: transparent;
}
[data-touchpoint=gtr_tablet][data-theme=velo] .button-module_splitButtonContainer__1m1qX {
  background: linear-gradient(90deg, #00acd5 0%, #006bb8 100%);
}
[data-touchpoint=gtr_tablet][data-theme=velo][data-locale=uae_gtr] .button-module_splitButtonContainer__1m1qX {
  background: transparent;
}
[data-touchpoint=gtr_tablet][data-theme=vuse] .button-module_splitButtonContainer__1m1qX {
  background: linear-gradient(90deg, rgb(241, 176, 44) 0%, rgb(235, 92, 33) 100%);
}
[data-touchpoint=gtr_tablet][data-theme=vuse][data-page=frankfurt] .button-module_splitButtonContainer__1m1qX {
  background: transparent;
}

[data-page=frankfurt] .button-module_splitButtonContainer__1m1qX {
  background: transparent !important;
}

.button-module_unitButton__1V_XZ.button-module_active__22Xnr {
  background-color: white !important;
  color: black !important;
}

.button-module_unitButton__1V_XZ {
  background-color: transparent;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  color: inherit;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  display: inline-block;
  min-width: 305px;
  padding: 20px 35px;
}
[data-theme=glo] .button-module_unitButton__1V_XZ {
  color: black;
}
[data-theme=velo] .button-module_unitButton__1V_XZ {
  background: #00aed6;
}
[data-locale=uk][data-theme=velo] .button-module_unitButton__1V_XZ {
  background: #69abd2;
}
[data-theme=glo] .button-module_unitButton__1V_XZ {
  background: white;
}
[data-locale=uae_gtr][data-theme=velo] .button-module_unitButton__1V_XZ {
  background: #fff;
  color: #192a53;
}
.button-module_unitButton__1V_XZ:not(:last-child) {
  border: none;
  border-right: 4px solid #17181a;
}
[data-theme=velo] .button-module_unitButton__1V_XZ:not(:last-child), [data-theme=glo] .button-module_unitButton__1V_XZ:not(:last-child), [data-locale=de][data-theme=vuse] .button-module_unitButton__1V_XZ:not(:last-child), [data-locale=de_gtr][data-theme=vuse] .button-module_unitButton__1V_XZ:not(:last-child), [data-locale=uk][data-theme=velo] .button-module_unitButton__1V_XZ:not(:last-child) {
  border-right: none;
  margin-right: 4px;
}
[data-touchpoint=gtr_tablet][data-theme=vuse] .button-module_unitButton__1V_XZ:not(:last-child) {
  border-right: 4px solid #3a3a38;
}
[data-theme=velo][data-locale=za] .button-module_unitButton__1V_XZ:not(:last-child) {
  border-right: 4px solid #0b3055;
}

[data-page=frankfurt] .button-module_unitButton__1V_XZ {
  background: #EDAE48 !important;
  border-right: none !important;
}

[data-page=frankfurt] .button-module_unitButton__1V_XZ.button-module_active__22Xnr {
  background-color: white !important;
  color: black !important;
}
/*

1. Font families
2. Default style variables

*/
.typography-module_mainTitle__18KKq {
  margin: 0;
  font-family: "Gotham", sans-serif;
  font-weight: 900;
  font-size: calc(var(--scale-factor) * 34px);
}
.typography-module_mainTitle__18KKq.typography-module_intro__1W-4f {
  color: white;
  margin: 33px 0 75px;
  text-align: center;
  text-transform: uppercase;
  font-size: calc(var(--scale-factor) * 40px);
  font-family: "Trim", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1;
}
.typography-module_mainTitle__18KKq.typography-module_productDetail__20BmD {
  font-weight: 500;
  font-size: calc(var(--scale-factor) * 40px);
  letter-spacing: 0em;
  line-height: 0.9;
}
[data-touchpoint=gtr_tablet] .typography-module_mainTitle__18KKq {
  font-size: 56px;
  line-height: 1.0714;
  letter-spacing: 0;
}

.typography-module_paragraph__N6gfm {
  margin: 0;
  font-family: "Gotham", sans-serif;
  font-weight: 400;
  font-size: calc(var(--scale-factor) * 16px);
  letter-spacing: -0.02em;
  line-height: 1.33333;
  color: white;
}
.typography-module_paragraph__N6gfm.typography-module_worldProducts__GSqGh {
  margin-bottom: 60px;
  font-size: calc(var(--scale-factor) * 18px);
  line-height: 1.571;
  letter-spacing: 0em;
  font-weight: 300;
}

.typography-module_productsTitle__3QTwe {
  margin: 45px 0 24.5px;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  font-size: calc(var(--scale-factor) * 30px);
  letter-spacing: -0.01em;
  line-height: 1.23;
}
.typography-module_productsTitle__3QTwe.typography-module_worldProducts__GSqGh {
  font-weight: 400;
  line-height: 0.814;
  letter-spacing: 0em;
  color: white;
  text-align: left;
  text-transform: uppercase;
}

.typography-module_secondaryTitle__11aLD {
  margin: 0;
  font-family: "Gotham", sans-serif;
  font-weight: 400;
  font-size: calc(var(--scale-factor) * 29px);
  white-space: pre-line;
  letter-spacing: 0em;
  line-height: 1.227;
  color: white;
}

[data-locale=us] .typography-module_mainTitle__18KKq {
  margin-top: 29px;
}
.slides-module_type-video__3FqRt,
.slides-module_type-image__1rM7v {
  background-color: #000;
}

.slides-module_color__1ErE9 {
  width: 100vw;
  height: 100vh;
}

.slides-module_image__1OkPM {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: #000;
}
.slides-module_image__1OkPM.slides-module_scaleIn__2oKY8 {
  transform: scale(1.1);
  animation: slides-module_scaleIn__2oKY8 1200ms ease-out forwards;
}
.slides-module_image__1OkPM.slides-module_image_1_5__1a3Fc {
  background-position: 0 center;
}
.slides-module_image__1OkPM.slides-module_image_2_5__3mwNz {
  background-position: 25% center;
}
.slides-module_image__1OkPM.slides-module_image_3_5__1Xvuc {
  background-position: 50% center;
}
.slides-module_image__1OkPM.slides-module_image_4_5__1bARc {
  background-position: 75% center;
}
.slides-module_image__1OkPM.slides-module_image_5_5__3l98H {
  background-position: 100% center;
}
.slides-module_image__1OkPM.slides-module_image_1_4__ljoHX {
  background-position: 12.5% center;
}
.slides-module_image__1OkPM.slides-module_image_2_4__iUinv {
  background-position: 37.5% center;
}
.slides-module_image__1OkPM.slides-module_image_3_4__2Q36N {
  background-position: 62.5% center;
}
.slides-module_image__1OkPM.slides-module_image_4_4__1Fdlp {
  background-position: 87.5% center;
}
.slides-module_image__1OkPM.slides-module_image_1_3__1uu8a {
  background-position: 25% center;
}
.slides-module_image__1OkPM.slides-module_image_2_3__3yqpX {
  background-position: 50% center;
}
.slides-module_image__1OkPM.slides-module_image_3_3__1t7rz {
  background-position: 75% center;
}
.slides-module_image__1OkPM.slides-module_image_1_2__1fE2f {
  background-position: 37.5% center;
}
.slides-module_image__1OkPM.slides-module_image_2_2__1ZCcb {
  background-position: 62.5% center;
}

.slides-module_videoWrapper__gqpb6 {
  position: relative;
  width: 100%;
  height: 100%;
}

.slides-module_video__FE-P8 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slides-module_video__FE-P8.slides-module_video_1_5__B5oKA {
  object-position: 0 center;
}
.slides-module_video__FE-P8.slides-module_video_2_5__2db66 {
  object-position: 25% center;
}
.slides-module_video__FE-P8.slides-module_video_3_5__1drHg {
  object-position: 50% center;
}
.slides-module_video__FE-P8.slides-module_video_4_5__gRHQz {
  object-position: 75% center;
}
.slides-module_video__FE-P8.slides-module_video_5_5__152c3 {
  object-position: 100% center;
}
.slides-module_video__FE-P8.slides-module_video_1_4__2KLFW {
  object-position: 12.5% center;
}
.slides-module_video__FE-P8.slides-module_video_2_4__2vPHa {
  object-position: 37.5% center;
}
.slides-module_video__FE-P8.slides-module_video_3_4__1RRGj {
  object-position: 62.5% center;
}
.slides-module_video__FE-P8.slides-module_video_4_4___GFSF {
  object-position: 87.5% center;
}
.slides-module_video__FE-P8.slides-module_video_1_3__2szrX {
  object-position: 25% center;
}
.slides-module_video__FE-P8.slides-module_video_2_3__1iPRH {
  object-position: 50% center;
}
.slides-module_video__FE-P8.slides-module_video_3_3__1zHSS {
  object-position: 75% center;
}
.slides-module_video__FE-P8.slides-module_video_1_2__15ESS {
  object-position: 37.5% center;
}
.slides-module_video__FE-P8.slides-module_video_2_2__1EFK_ {
  object-position: 62.5% center;
}

@keyframes slides-module_scaleIn__2oKY8 {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
/*

1. Font families
2. Default style variables

*/
.mediaslider-module_slider__2oNiU {
  min-width: 600px;
  min-height: 600px;
  overflow: hidden;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.mediaslider-module_slide__1GM-s {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  will-change: opacity, transform;
}

.mediaslider-module_type-video__38VVa,
.mediaslider-module_type-image__2fbQD {
  background-color: #000;
}

.mediaslider-module_image__2ndPK {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.mediaslider-module_video__1aOEP {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mediaslider-module_contentContainer__2CGQG {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.mediaslider-module_contentContainer__2CGQG.mediaslider-module_video__1aOEP {
  position: absolute;
  margin: auto;
}

.mediaslider-module_foregroundImage__1Ur6V {
  width: 445px;
  height: auto;
  margin: 20px;
  opacity: 0;
}
.mediaslider-module_foregroundImage__1Ur6V.mediaslider-module_translate__3I7jM {
  transform: translate(var(--animation-parameter-x, 0), var(--animation-parameter-y, 0));
  animation: mediaslider-module_slideIn__13ley var(--animation-duration, 400ms) ease-out forwards;
}
.mediaslider-module_foregroundImage__1Ur6V.mediaslider-module_scale__AhPgX {
  transform: scale(var(--animation-parameter-scale, 0.8));
  animation: mediaslider-module_scaleIn__1t273 var(--animation-duration, 400ms) ease-out forwards;
}

.mediaslider-module_copyContainer__2xo0c {
  color: white;
  margin: 20px;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_outlines__31OhK {
  color: transparent !important;
  -webkit-text-stroke-width: 0.018em;
  -webkit-text-stroke-color: white;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Trim", sans-serif;
  font-weight: 700;
  line-height: 1;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M.mediaslider-module_translate__3I7jM {
  opacity: var(--animation-parameter-opacity, 0);
  transform: translate(var(--animation-parameter-x, 0), var(--animation-parameter-y, 0));
  animation: mediaslider-module_slideIn__13ley var(--animation-duration, 400ms) ease-out forwards;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M.mediaslider-module_scale__AhPgX {
  opacity: var(--animation-parameter-opacity, 0);
  transform: scale(var(--animation-parameter-scale, 0.8));
  animation: mediaslider-module_scaleIn__1t273 var(--animation-duration, 400ms) ease-out forwards;
}
[data-slide-theme=vype] .mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M, [data-slide-theme=vuse] .mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M {
  letter-spacing: 0.02em;
  line-height: 1;
}
[data-slide-theme=glo] .mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M {
  font-family: "Mont", sans-serif;
  font-weight: 900;
}
[data-slide-theme=velo] .mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M {
  font-family: "Velo", sans-serif;
  font-weight: 600;
}
[data-slide-theme=velo] .mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M.mediaslider-module_medium__1euNd {
  font-size: 150px;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M.mediaslider-module_small__2-zRI {
  font-size: 130px;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M.mediaslider-module_medium__1euNd {
  font-size: 140px;
}
.mediaslider-module_copyContainer__2xo0c .mediaslider-module_textLine__jf5_M.mediaslider-module_large__1l9fj {
  font-size: 220px;
}

@keyframes mediaslider-module_slideIn__13ley {
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes mediaslider-module_scaleIn__1t273 {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.multi-slider-module_slider__2k_-0 {
  min-width: 600px;
  min-height: 600px;
  overflow: hidden;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.multi-slider-module_slide__VozNa {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}
.flavour-canvas-module_wrapper__1tK5c {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 1 !important;
}

.flavour-canvas-module_canvas__2je9o {
  position: relative;
  z-index: 1;
}
[data-locale=uk][data-theme=vuse]:not([data-screentype=tv]) .flavour-canvas-module_canvas__2je9o {
  background: #161819 !important;
  background-image: none !important;
}

.flavour-canvas-module_ui__1H9Wg {
  z-index: 2;
}
/*

1. Font families
2. Default style variables

*/
.warnings-module_warning__23Nop {
  position: absolute;
  z-index: 10;
}

[data-locale=za] .warnings-module_image__2CfvM {
  width: auto;
  height: 64px;
}
[data-locale=uk] .warnings-module_image__2CfvM {
  width: 80px;
  height: auto;
}
[data-screentype=tv] [data-locale=uk] .warnings-module_image__2CfvM {
  width: 100px;
}

.warnings-module_cornerImage__2y5RJ {
  bottom: 30px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.warnings-module_cornerImage__icon__3nrvt {
  margin: 0 auto;
}

.warnings-module_warningModalTrigger__3FBbL {
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  font-size: 12px;
  white-space: pre;
  line-height: 1.33;
  letter-spacing: 0;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: white;
  z-index: 110;
}
.warnings-module_warningModalTrigger__3FBbL span {
  display: inline-flex;
}

.warnings-module_warningsModal__z2hEX .warnings-module_title__12TQ5 {
  font-weight: 700;
  font-size: 20px;
  display: block;
  margin-bottom: 43px;
}
.warnings-module_warningsModal__z2hEX .warnings-module_paragraph__1JNFc {
  display: block;
  margin-bottom: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
}

[data-page=page].warnings-module_warningModalTrigger__3FBbL {
  display: none !important;
}

[data-page=frankfurt] .warnings-module_warningModalTrigger__3FBbL {
  display: block !important;
}
/*

1. Font families
2. Default style variables

*/
.sign-up-module_signUpModal__2rUSK {
  padding: 10px 60px;
  font-family: "Gotham", sans-serif;
  text-align: center;
  color: black;
  min-width: 780px;
  min-height: 343px;
  user-select: none;
}
.sign-up-module_signUpModal__2rUSK iframe {
  min-height: 215px;
  width: 100%;
  border: none;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_title__3jCvo {
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.06em;
  line-height: 1;
  text-transform: uppercase;
  padding: 0 40px;
  margin-bottom: 14px;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_text__2UMBI {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.25;
  padding: 0 40px;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb {
  display: block;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb .sign-up-module_fieldsContainer__1fGfo {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb label {
  color: black;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.08em;
  line-height: 0.36;
  text-transform: uppercase;
  text-align: left;
  width: 300px;
  padding-top: 30px;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb input {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 0.36;
  text-transform: uppercase;
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb input:invalid {
  color: black;
  transition: all 250ms easeOut;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb input:invalid.sign-up-module_error__1Oh_v {
  color: #da2128;
  transition: all 250ms easeOut;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb input:focus {
  outline: none;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb input:not([type=checkbox]) {
  width: 300px;
  height: 30px;
  line-height: 1;
  background-color: transparent;
  margin-top: 20px;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb input[type=checkbox] {
  display: none;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb .sign-up-module_errorCheckbox__1_l9w {
  color: #da2128;
  transition: all 250ms easeOut;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb .sign-up-module_errorCheckbox__1_l9w .sign-up-module_checkbox__3CDhh {
  border: 1.5px solid #da2128;
  transition: all 250ms easeOut;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb .sign-up-module_checkbox__3CDhh {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: white;
  border: 1.5px solid black;
  margin-right: 15px;
  transition: all 250ms easeOut;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb .sign-up-module_checkbox__3CDhh.sign-up-module_checked__mGB-s {
  border: none;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_form__2IOOb .sign-up-module_checkbox__3CDhh img {
  width: 14px;
  height: 14px;
  object-fit: contain;
  object-position: center;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_smallPrint__2idX_ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: pre-line;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0em;
  line-height: 1.375;
  margin: 25px 0;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_smallPrint__2idX_ .sign-up-module_textContainer__3NgJx {
  max-width: 400px;
  text-align: left;
}
.sign-up-module_signUpModal__2rUSK .sign-up-module_smallPrint__2idX_ .sign-up-module_submit__zZYw6 {
  padding: 9px 55px;
}
.sign-up-module_error__1Oh_v::placeholder {
  color: #da2128;
  transition: all 250ms easeOut;
}

.sign-up-module_thanks__2n7GE {
  display: flex;
  align-items: center;
  font-family: "Gotham", sans-serif;
  text-align: center;
  color: black;
  min-width: 780px;
  min-height: 343px;
}
.sign-up-module_thanks__2n7GE .sign-up-module_text__2UMBI {
  margin: -40px auto 0;
  max-width: 490px;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}

.sign-up-module_hidden__2HseD {
  display: none;
}
/*

1. Font families
2. Default style variables

*/
.layout-module_layout__1k4MV {
  position: relative;
  overflow: hidden;
  grid-area: content;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: var(--theme-color-primary);
}

.layout-module_vype__2X-zQ {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Gotham, sans-serif;
  --theme-color-primary: white;
}

.layout-module_glo__1wTqU {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: ITCAvantGarde, sans-serif;
  --theme-color-primary: white;
}

.layout-module_lyft__22KBj,
.layout-module_epok__A_dQ7 {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Foundry, sans-serif;
  --theme-color-primary: black;
}

.layout-module_velo__KxHDO {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Euclid, sans-serif;
}
[data-touchpoint=trial_tablet] .layout-module_velo__KxHDO {
  --theme-color-primary: white;
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Velo, sans-serif;
}
[data-touchpoint=gtr_tablet] .layout-module_velo__KxHDO, [data-locale=uk] .layout-module_velo__KxHDO {
  --theme-font-brand: Velo, sans-serif;
}
.corners-module_corner__RNdDx {
  opacity: 0;
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 900;
  display: none;
}
[data-touchpoint=gtr_tablet] .corners-module_corner__RNdDx {
  display: block;
  width: 50px;
  height: 50px;
}
[data-touchpoint=gtr_tablet][data-locale=de_gtr] .corners-module_corner__RNdDx {
  display: none;
}

.corners-module_top__3Ds5S {
  top: 0;
}

.corners-module_bottom__1_BxI {
  bottom: 0;
}

.corners-module_left__1h7bP {
  left: 0;
}

.corners-module_right__2y-D2 {
  right: 0;
}

.corners-module_modal__2OmKe {
  padding: 40px 40px 80px;
  flex-direction: column;
  display: flex;
}

.corners-module_version__1Q6rm {
  text-align: center;
  padding: 0 20px 20px;
}
/*

1. Font families
2. Default style variables

*/
.disclaimer-module_locale-us__34nfL, .disclaimer-module_locale-pl__5YKWa, .disclaimer-module_locale-za__2VtWR, .disclaimer-module_locale-de__1LW-A, [data-touchpoint=gtr_tablet] .disclaimer-module_container__1LU-E, .disclaimer-module_locale-uk__3EEG6 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.disclaimer-module_disclaimer__3vD8k {
  grid-area: disclaimer;
  position: relative;
}
.disclaimer-module_disclaimer__3vD8k.disclaimer-module_disclaimerDefault__974_a {
  height: auto;
}
.disclaimer-module_disclaimer__3vD8k.disclaimer-module_disclaimerXS__3j8-y {
  --disclaimer-height: 40px;
  height: 40px;
}
.disclaimer-module_disclaimer__3vD8k.disclaimer-module_disclaimerSmall__2gZiE {
  --disclaimer-height: 10vh;
  height: 10vh;
}
.disclaimer-module_disclaimer__3vD8k.disclaimer-module_disclaimerMedium__25Sfh {
  height: 20vh;
}
[data-locale=us] .disclaimer-module_disclaimer__3vD8k.disclaimer-module_disclaimerMedium__25Sfh {
  height: 20vh;
}
.disclaimer-module_disclaimer__3vD8k.disclaimer-module_disclaimer_80__3Yb9K {
  --disclaimer-height: 80px;
  height: 80px;
}

[data-locale=za] .disclaimer-module_disclaimerSmall__2gZiE {
  box-shadow: 0 -10px 10px 1px rgba(0, 0, 0, 0.1);
  z-index: 10;
  --disclaimer-height: 10vh;
  height: 10vh;
}
[data-locale=za] .disclaimer-module_disclaimerSmall__2gZiE .disclaimer-module_text__2FqH7 {
  font-size: 38px;
  letter-spacing: 0;
}

.disclaimer-module_container__1LU-E {
  z-index: 10;
  background-color: black;
  overflow: hidden;
}
[data-touchpoint=gtr_tablet] .disclaimer-module_container__1LU-E, [data-locale=uk] .disclaimer-module_container__1LU-E {
  background: #161819;
}

.disclaimer-module_text__2FqH7 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.2em;
  white-space: pre-wrap;
}

.disclaimer-module_screenType-mobile__2xC-J {
  font-size: 10px;
}
.disclaimer-module_screenType-mobile__2xC-J .disclaimer-module_text__2FqH7 {
  max-width: 320px;
}

.disclaimer-module_symbol__2DyQ5 {
  height: 2em;
  width: auto;
  margin-right: 10px;
}
.disclaimer-module_disclaimerXS__3j8-y .disclaimer-module_symbol__2DyQ5 {
  height: 32px;
}

.disclaimer-module_screenType-mobile__2xC-J {
  font-size: 10px;
}
.disclaimer-module_screenType-mobile__2xC-J .disclaimer-module_text__2FqH7 {
  max-width: 69%;
}

.disclaimer-module_locale-uk__3EEG6 {
  border-top: solid 1px #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  font-size: 17px;
}

[data-touchpoint=welcome_tv][data-screentype=tv] .disclaimer-module_locale-uk__3EEG6 {
  font-size: calc(var(--scale-factor) * 12px);
}

[data-touchpoint=gtr_tablet] .disclaimer-module_container__1LU-E {
  border-top: solid 1px #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  font-size: 23px;
}

[data-locale=de_gtr] .disclaimer-module_container__1LU-E {
  font-size: calc(var(--scale-factor) * 12px);
}

.disclaimer-module_locale-de__1LW-A {
  border-top: solid 1px #fff;
  font-size: calc(var(--scale-factor) * 12px);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
}

.disclaimer-module_locale-za__2VtWR {
  background-color: white;
  border: none;
  font-size: calc(var(--scale-factor) * 31px);
  letter-spacing: 0.018em;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  color: black;
}
[data-screentype=tv] .disclaimer-module_locale-za__2VtWR {
  background-color: black;
  border-top: 1px solid white;
}
[data-screentype=tv] .disclaimer-module_locale-za__2VtWR .disclaimer-module_text__2FqH7 {
  color: white;
  font-size: 48px;
  letter-spacing: 0.01em;
  line-height: 1.16;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  justify-content: center;
  padding: 0 140px;
  width: 100vw;
}
[data-screentype=mobile] .disclaimer-module_locale-za__2VtWR .disclaimer-module_text__2FqH7 {
  font-size: 14px;
  line-height: 1.3;
  max-width: 96%;
}
@media (min-height: 500px) {
  [data-screentype=mobile] .disclaimer-module_locale-za__2VtWR .disclaimer-module_text__2FqH7 {
    font-size: 15px;
  }
}
@media (min-height: 600px) {
  [data-screentype=mobile] .disclaimer-module_locale-za__2VtWR .disclaimer-module_text__2FqH7 {
    font-size: 16px;
  }
}

.disclaimer-module_locale-pl__5YKWa {
  background-color: white;
  border: solid 9px black;
  font-size: calc(var(--scale-factor) * 31px);
  letter-spacing: 0.018em;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  color: black;
}
.disclaimer-module_locale-pl__5YKWa.disclaimer-module_screenType-tv__2abL0 {
  height: 192px;
  font-size: 40px;
}

.disclaimer-module_locale-us__34nfL {
  background-color: white;
  border: 10px solid #333333;
}
.disclaimer-module_locale-us__34nfL .disclaimer-module_symbol__2DyQ5 {
  display: none;
}
.disclaimer-module_locale-us__34nfL .disclaimer-module_text__2FqH7 {
  font-family: "Univers-Bold", sans-serif;
  font-size: 55px;
}

.disclaimer-module_learnCompareDisclaimer__3SGR_ {
  color: black;
}
.background-module_container__3xobF {
  grid-area: content;
  z-index: 0;
}
.socket-status-module_status__2UeyZ {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 2vw;
  height: 2vw;
  z-index: 1000;
  pointer-events: none;
  background: transparent;
}

.socket-status-module_disconnected__sIHT_ {
  background: #f00;
}
.console-overlay-module_overlay__1B24P {
  position: fixed;
  bottom: 0;
  max-height: 100vh;
  overflow: auto;
  z-index: 800;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
}
/*

1. Font families
2. Default style variables

*/
/* Box sizing rules */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
video {
  max-width: 100%;
  display: inline-block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html,
body {
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  touch-action: manipulation;
}

* {
  user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}
a:focus,
button:focus {
  outline: none;
}

body {
  background-color: #000;
  width: 100vw;
  height: 100vh;
}

#__modal {
  position: fixed;
  top: 0;
  left: 0;
}

:root {
  --font-weight-light: 300;
  --font-weight-book: 400;
  --font-weight-medium: 500;
  --font-weight-demi: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}

.appwrapper-module_screenType-mobile__e2zp3 {
  --page-width: 100vw;
  --page-height: 100vh;
  --scale-factor: 1;
}

.appwrapper-module_screenType-qb13r__3dM09 {
  --page-width: 1920px;
  --page-height: 1080px;
  --scale-factor: 1.4;
}

.appwrapper-module_screenType-tab-s4__3q8mz {
  --page-width: 1280px;
  --page-height: 800px;
  --scale-factor: 1;
}

.appwrapper-module_screenType-tv__2TJO5 {
  --page-width: 1080px;
  --page-height: 1920px;
  --scale-factor: 1;
}

.appwrapper-module_wrapper__2nJhB {
  position: fixed;
  width: var(--page-width);
  height: var(--page-height);
  font-family: var(--theme-font-primary);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: var(--page-width);
  grid-template-areas: "layout" "disclaimer";
}

.us .appwrapper-module_wrapper__2nJhB {
  color: black;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "disclaimer" "layout";
}

.appwrapper-module_content__AA8MD {
  grid-area: layout;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "content";
}
[data-locale=uk] .appwrapper-module_content__AA8MD {
  grid-template-rows: 100%;
}

.appwrapper-module_vuse__28WqK {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Gotham, sans-serif;
  --theme-color-primary: white;
}

[data-locale=us][data-touchpoint=trial_tablet].appwrapper-module_vuse__28WqK {
  --theme-color-primary: black;
}

.appwrapper-module_vype__1C6Eq {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Gotham, sans-serif;
  --theme-color-primary: white;
}

.appwrapper-module_glo__2G87- {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: ITCAvantGarde, sans-serif;
  --theme-color-primary: white;
}

.appwrapper-module_lyft__3HtDz {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Foundry, sans-serif;
  --theme-color-primary: black;
}

[data-theme=velo][data-touchpoint=trial_tablet].appwrapper-module_velo__dn47o {
  --theme-color-primary: white;
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Velo, sans-serif;
}

.appwrapper-module_velo__dn47o {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Euclid, sans-serif;
  --theme-color-primary: black;
}
[data-touchpoint=gtr_tablet] .appwrapper-module_velo__dn47o, [data-locale=uk] .appwrapper-module_velo__dn47o {
  --theme-font-brand: Velo, sans-serif;
}

.appwrapper-module_vuse-epod__348pB {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Gotham, sans-serif;
  --theme-color-primary: white;
}

.appwrapper-module_vuse-epen__3K8qt {
  --theme-font-primary: Gotham, sans-serif;
  --theme-font-brand: Gotham, sans-serif;
  --theme-color-primary: white;
}
/*

1. Font families
2. Default style variables

*/
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_line__2WmGu {
  width: 74px;
  height: 2px;
  margin: 25px auto;
  background-color: #fff;
}

.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  white-space: pre-line;
}
[data-locale=us][data-theme=velo] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU {
  z-index: 0;
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_columnWrapper__NTjWB {
  position: relative;
  width: 50%;
  margin: auto 0;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_columnWrapper__NTjWB:last-child {
  border-left: 2px solid rgba(255, 255, 255, 0.5);
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_columnWrapper__NTjWB .age-page-module_logo__24lHU {
  width: calc(var(--scale-factor) * 200px);
  height: auto;
  margin: auto;
}
[data-locale=us][data-theme=velo] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_columnWrapper__NTjWB .age-page-module_logo__24lHU {
  display: none;
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_title__1lcs1 {
  text-align: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  line-height: 1.07;
}
[data-locale=us][data-theme=velo][data-touchpoint=trial_tablet] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_title__1lcs1 {
  font-family: "Velo", sans-serif;
  font-weight: 600;
  font-size: 24px;
}
[data-locale=uk] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_title__1lcs1 {
  text-transform: initial;
  font-family: var(--theme-font-brand);
}
[data-locale=uk][data-theme=velo] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_title__1lcs1 {
  font-family: var(--theme-font-brand);
  font-weight: 500;
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_title__1lcs1 .age-page-module_brandNameTitle__1-Af- {
  font-size: 200px;
  line-height: 165px;
  font-family: "Velo", sans-serif;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 33px;
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_para__YhNNv {
  padding: 0 30px;
  text-align: center;
}
[data-locale=us] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_para__YhNNv {
  line-height: 37px;
}
[data-locale=us][data-theme=velo][data-touchpoint=trial_tablet] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_para__YhNNv {
  font-family: "Velo", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}
[data-locale=uk] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_para__YhNNv, [data-locale=za] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_para__YhNNv {
  font-size: 26px;
  line-height: 1.3;
}
[data-locale=us][data-theme=velo][data-touchpoint=trial_tablet] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_line__2WmGu {
  display: none;
}
[data-locale=de_gtr] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU:not(.age-page-module_one_column__3yRzA) .age-page-module_line__2WmGu {
  display: none !important;
}
.age-page-module_container__2XuKk.age-page-module_landscape__2jDIU .age-page-module_btnContainer__2aKD6 {
  margin-top: 43.5px;
  text-align: center;
}

[data-locale=us] .age-page-module_btnContainer__2aKD6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 62%;
  margin: auto;
}
[data-locale=us] .age-page-module_container__2XuKk.age-page-module_landscape__2jDIU .age-page-module_para__YhNNv {
  padding: 0 143px;
}
[data-locale=us] [data-theme=velo] .age-page-module_btnContainer__2aKD6 {
  width: 70%;
}

.age-page-module_container__2XuKk.age-page-module_portrait__299MO {
  display: flex;
  flex-direction: column;
  padding-top: 220px;
  position: relative;
  height: 100%;
  z-index: 5;
  white-space: pre-line;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_columnWrapper__NTjWB {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  width: 520px;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_columnWrapper__NTjWB h1 {
  font-size: 56px;
  line-height: 1.1;
  letter-spacing: -0.001em;
  padding-bottom: 45px;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_columnWrapper__NTjWB p {
  font-size: 32.94px;
  font-weight: 400;
  line-height: 1.333;
  letter-spacing: -0.02em;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_columnWrapper__NTjWB .age-page-module_logo__24lHU {
  width: 305.25px;
  height: auto;
  margin: 0 auto 200px;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_btnContainer__2aKD6 {
  display: flex;
  flex-direction: column-reverse;
  align-self: center;
  margin: 45px auto;
  width: 80%;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_btnContainer__2aKD6 > button {
  font-size: 24.25px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.age-page-module_container__2XuKk.age-page-module_portrait__299MO .age-page-module_btnContainer__2aKD6 > button:first-child {
  margin-top: 45px;
}

.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA {
  flex-flow: column nowrap;
}
.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_columnWrapper__NTjWB {
  text-align: center;
}
.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_columnWrapper__NTjWB .age-page-module_logo__24lHU {
  width: auto;
  height: 200px;
  margin-bottom: 55px;
}
[data-theme=velo] .age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_columnWrapper__NTjWB .age-page-module_logo__24lHU {
  height: 135px;
}
.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_title__1lcs1 {
  margin-bottom: 18px;
  font-family: "Gotham", sans-serif;
  font-weight: 900;
  line-height: 0.9;
  font-size: 48px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}
[data-touchdevice=gtr_tablet] .age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_title__1lcs1 {
  font-size: 56px;
  line-height: 1.071;
  letter-spacing: 0;
}
[data-locale=za] .age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_title__1lcs1 {
  line-height: 1.071;
}
[data-theme=velo] .age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_title__1lcs1 {
  font-family: "Velo", sans-serif;
  font-weight: 600;
}
.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_para__YhNNv {
  font-size: 32px;
  padding: 0 30px;
  text-align: center;
  padding: 42px 0;
}
[data-theme=velo] .age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_para__YhNNv {
  letter-spacing: 0;
  line-height: 40px;
  font-family: "Velo", sans-serif;
  font-weight: 500;
}
[data-locale=za] .age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_para__YhNNv {
  font-size: 31px;
  margin-top: 20px;
}
.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_line__2WmGu {
  display: none;
}
.age-page-module_container__2XuKk.age-page-module_one_column__3yRzA .age-page-module_btnContainer__2aKD6 button {
  margin: 10px 22px;
}

.age-page-module_hasAgeVerification__21mcK .age-page-module_disabled__2yCHH {
  opacity: 0.25 !important;
  pointer-events: none !important;
  cursor: pointer !important;
}

.age-page-module_selects__2uLpN {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.age-page-module_selects__2uLpN > * {
  margin: 16px 16px 0;
  text-align: left;
}
.age-page-module_selects__2uLpN p {
  font-family: "Gotham", sans-serif;
  font-weight: 700;
  font-size: calc(var(--scale-factor) * 12px);
  letter-spacing: 0.12em;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.age-page-module_selects__2uLpN select {
  padding: 11px 14px;
  background: #efefef;
  min-width: 170px;
  appearance: none;
  position: relative;
  font-size: 18px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAASCAMAAAANFixgAAAAb1BMVEUAAAATExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExPbeRqoAAAAJHRSTlMA9wXv5b23cjgpIg8K6t7Iw6+poJqRioB+eG1nZF5VT0dBLxyj9ORXAAAAoUlEQVQoz33KRw6DQBQE0R4wyeCcyaHuf0YLJETmbWZa9RW4oTaFbiCHS7jZLzhqzlwjrYqunBvpeMCJVrvD4Sh1F66lBctteyc4cbMW/cYp6Edtc7dm/Y5dD9O3eWjige1rxDc8NfLE+JqoDK9hvTCVZkrDu/+/MaUWCsNHnQ+m0Iocfu37g1yrMvAkDzJtSCFJINWmGCDWDg887fp+NfUHgbQLMVtJJyEAAAAASUVORK5CYII=");
  background-position: 90% 20px;
  background-size: 18px 9px;
  background-repeat: no-repeat;
  background-color: white;
}
/*

1. Font families
2. Default style variables

*/
.under-age-module_underAge__Szn3J {
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  color: white;
  z-index: 10;
  display: flex;
  align-items: center;
}
.under-age-module_underAge__Szn3J.under-age-module_bgTranslucid__1T3So {
  background: rgba(0, 0, 0, 0.5);
}
[data-locale=uk] .under-age-module_underAge__Szn3J {
  background: #161819;
}
.under-age-module_underAge__Szn3J p {
  font-size: calc(var(--scale-factor) * 24px);
  line-height: 1.222;
}
[data-locale=us][data-theme=velo][data-touchpoint=trial_tablet] .under-age-module_underAge__Szn3J p {
  font-family: "Velo", sans-serif;
  font-weight: 500;
}
[data-locale=uk] .under-age-module_underAge__Szn3J p, [data-locale=za] .under-age-module_underAge__Szn3J p {
  font-size: 26px;
}
.under-age-module_portrait__1PGK1 .under-age-module_underAge__Szn3J p {
  font-size: 33px;
  font-weight: 400;
  line-height: 1.333;
  letter-spacing: -0.02em;
}

.under-age-module_underAgeWrapper__IKxCv {
  text-align: center;
  width: 500px;
  margin: auto;
}

.under-age-module_logo__E6S7e {
  width: 150px;
  margin-bottom: 38px;
}
.under-age-module_portrait__1PGK1 .under-age-module_logo__E6S7e {
  width: 220px;
}
[data-locale=us] .under-age-module_logo__E6S7e {
  width: 288px;
}
[data-locale=uk][data-theme=velo][data-touchpoint=trial_tablet] .under-age-module_logo__E6S7e {
  width: 210px;
}
[data-locale=uk] .under-age-module_logo__E6S7e, [data-locale=za] .under-age-module_logo__E6S7e {
  width: 278px;
}
/*

1. Font families
2. Default style variables

*/
.idle-screener-module_containerTV__yxm6c {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.idle-screener-module_containerTV__yxm6c .idle-screener-module_wrapperMessage__15L_3 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 60%;
  height: 100%;
  margin: 0 auto;
  font-size: 124px;
  line-height: 1.035;
  letter-spacing: -0.01em;
  font-family: "Gotham", sans-serif;
  font-weight: 900;
  text-align: center;
}

.idle-screener-module_containerTablet__25Mqu {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.error-catcher-module_layout__gs0ew {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-catcher-module_error__1Cbna {
  max-width: 760px;
  color: #fff;
}

.error-catcher-module_message__3yOgg {
  opacity: 0.9;
  margin-top: 40px;
  background: #fff;
  color: #000;
  padding: 2px 6px;
}
.loading-module_loadingParent__2Y7km {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.loading-module_loading__mNIR_ {
  position: relative;
  width: 64px;
  height: 64px;
}
.loading-module_loading__mNIR_ div {
  animation: loading-module_spinner__3B41A 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.loading-module_loading__mNIR_ div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.loading-module_loading__mNIR_ div:nth-child(1) {
  animation-delay: -0.036s;
}
.loading-module_loading__mNIR_ div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.loading-module_loading__mNIR_ div:nth-child(2) {
  animation-delay: -0.072s;
}
.loading-module_loading__mNIR_ div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.loading-module_loading__mNIR_ div:nth-child(3) {
  animation-delay: -0.108s;
}
.loading-module_loading__mNIR_ div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.loading-module_loading__mNIR_ div:nth-child(4) {
  animation-delay: -0.144s;
}
.loading-module_loading__mNIR_ div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.loading-module_loading__mNIR_ div:nth-child(5) {
  animation-delay: -0.18s;
}
.loading-module_loading__mNIR_ div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.loading-module_loading__mNIR_ div:nth-child(6) {
  animation-delay: -0.216s;
}
.loading-module_loading__mNIR_ div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.loading-module_loading__mNIR_ div:nth-child(7) {
  animation-delay: -0.252s;
}
.loading-module_loading__mNIR_ div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.loading-module_loading__mNIR_ div:nth-child(8) {
  animation-delay: -0.288s;
}
.loading-module_loading__mNIR_ div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes loading-module_spinner__3B41A {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-module_spinner_outer__3w0Vy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.spinner-module_spinner_outer__3w0Vy.spinner-module_welcome__262V9 {
  opacity: 0;
}

.spinner-module_spinner_inner__wAQWI {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.spinner-module_canvas__1mzoi {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.spinner-module_spinner_flare__3wcp_ {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  background-size: cover;
}

.spinner-module_buttons__1M1ny {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 5px;
  margin: 40px auto;
  text-align: center;
  z-index: 10;
}

.spinner-module_btn__38j1r,
.spinner-module_btn_dev__3ak6K {
  display: inline-block;
  min-width: 94px;
  padding: 10px;
  margin: 2px;
  border-radius: 3px;
  border: none;
  background: #333;
  text-align: center;
  text-decoration: none;
  color: #eee;
  cursor: pointer;
}
.spinner-module_btn__38j1r:hover,
.spinner-module_btn_dev__3ak6K:hover {
  background: #444;
}

.spinner-module_btn_dev__3ak6K {
  background: #222;
  color: #666;
}
.spinner-module_btn_dev__3ak6K:hover {
  background: #333;
}
.spinner-module_spinner_outer__1RUco {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.spinner-module_spinner_outer__1RUco.spinner-module_welcome__1ZZxi {
  opacity: 0;
}

.spinner-module_spinner_inner__10SBH {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.spinner-module_canvas__28pKq {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.spinner-module_spinner_flare__2Dwk0 {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  background-size: cover;
}

.spinner-module_buttons__1lWQ- {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 5px;
  margin: 40px auto;
  text-align: center;
  z-index: 10;
}

.spinner-module_btn__2q4Io,
.spinner-module_btn_dev__1HMI5 {
  display: inline-block;
  min-width: 94px;
  padding: 10px;
  margin: 2px;
  border-radius: 3px;
  border: none;
  background: #333;
  text-align: center;
  text-decoration: none;
  color: #eee;
  cursor: pointer;
}
.spinner-module_btn__2q4Io:hover,
.spinner-module_btn_dev__1HMI5:hover {
  background: #444;
}

.spinner-module_btn_dev__1HMI5 {
  background: #222;
  color: #666;
}
.spinner-module_btn_dev__1HMI5:hover {
  background: #333;
}
/*

1. Font families
2. Default style variables

*/
.flavour-carousel-module_carouselContainer__2c4_L {
  padding-left: 138px;
  padding-top: 160px;
  max-width: 100vw;
  overflow-y: scroll;
}
[data-theme=glo][data-locale=de] .flavour-carousel-module_carouselContainer__2c4_L [data-theme=glo][data-locale=de_gtr] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-left: 78px;
}
[data-locale=de_gtr] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-left: 58px;
}
[data-theme=vuse][data-locale=uae_gtr] .flavour-carousel-module_carouselContainer__2c4_L, [data-theme=vuse][data-locale=de_gtr] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-left: 61px;
}
.flavour-carousel-module_carouselContainer__2c4_L::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}
[data-theme=vuse][data-touchpoint=education] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-top: 120px;
  padding-left: 105px;
}
[data-theme=vuse][data-touchpoint=education][data-locale=za] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-top: 150px;
  padding-left: 160px;
}
[data-theme=velo][data-touchpoint=gtr_tablet][data-locale=uae_gtr] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-left: 50px;
  padding-top: 160px;
}
[data-theme=vuse] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-top: 220px;
}
[data-locale=uae_gtr][data-theme=vuse] .flavour-carousel-module_carouselContainer__2c4_L {
  padding-top: 160px;
}

.flavour-carousel-module_carouselWrapper__3einy {
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
}
[data-touchpoint=education] .flavour-carousel-module_carouselWrapper__3einy {
  padding-right: 138px;
  opacity: 0;
  transform: translateX(1200px);
  animation-name: flavour-carousel-module_slide-appear-edu__5a2xQ;
  animation-duration: 1600ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.5, 1.8, 0.6, 1.06);
  animation-delay: 500ms;
}

.flavour-carousel-module_carouselItem__2GCC6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-right: 35px;
  min-width: 415px;
}
[data-theme=glo][data-locale=de] .flavour-carousel-module_carouselItem__2GCC6, [data-theme=vype][data-locale=de_gtr] .flavour-carousel-module_carouselItem__2GCC6 {
  min-width: 220px;
  padding-right: 30px;
}
[data-touchpoint=gtr_tablet] .flavour-carousel-module_carouselItem__2GCC6 {
  opacity: 0;
  transform: translateX(300px);
  animation-name: flavour-carousel-module_slide-appear__33wsg;
  animation-fill-mode: forwards;
  animation-duration: 700ms;
  animation-delay: calc(var(--animation-order) * 200ms);
}
[data-touchpoint=education] .flavour-carousel-module_carouselItem__2GCC6 {
  min-width: 400px;
  padding-right: 29px;
  padding-left: 29px;
  opacity: 0;
  animation-name: flavour-carousel-module_slide-in-small__3WsJ9;
  animation-timing-function: ease-in-out;
  animation-duration: 700ms;
  animation-fill-mode: forwards;
  animation-delay: calc(var(--animation-order) * 150ms + 500ms);
}
[data-touchpoint=education][data-locale=de] .flavour-carousel-module_carouselItem__2GCC6 {
  padding-right: 10px;
  padding-left: 10px;
}
.flavour-carousel-module_carouselItem__2GCC6 span {
  flex: 0 0 auto;
  display: block;
  max-width: 346px;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: white;
}
[data-touchpoint=education] .flavour-carousel-module_carouselItem__2GCC6 span {
  max-width: 472px;
}
[data-locale=de][data-theme=vype] .flavour-carousel-module_carouselItem__2GCC6 span {
  max-width: 472px;
}
[data-theme=velo] .flavour-carousel-module_carouselItem__2GCC6 span {
  color: black;
}
[data-locale=za][data-theme=velo] .flavour-carousel-module_carouselItem__2GCC6 span {
  color: white;
}

.flavour-carousel-module_image__eEOnr {
  flex: 0 0 auto;
  display: block;
  margin: 0 auto 20px;
  height: 380px;
  width: auto;
  max-width: unset;
}
[data-touchpoint=education] .flavour-carousel-module_image__eEOnr {
  height: 530px;
  margin-bottom: 30px;
}
[data-theme=glo] .flavour-carousel-module_image__eEOnr {
  margin-bottom: 15px;
  height: 440px;
}
[data-locale=za] .flavour-carousel-module_image__eEOnr {
  height: 400px;
}
[data-theme=velo][data-locale=za] .flavour-carousel-module_image__eEOnr {
  height: 360px;
  margin-bottom: 0px;
}
[data-theme=vype][data-locale=de] .flavour-carousel-module_image__eEOnr {
  margin-bottom: 0px;
  height: 460px;
}

span.flavour-carousel-module_title__3SvJK {
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 5px;
}
[data-theme=vype][data-locale=de] span.flavour-carousel-module_title__3SvJK {
  font-size: 22px;
}
[data-touchpoint=education] span.flavour-carousel-module_title__3SvJK {
  font-size: 22px;
  letter-spacing: 0;
}
[data-theme=velo] span.flavour-carousel-module_title__3SvJK {
  color: #192a53;
  font-family: "Velo", sans-serif;
  font-weight: 600;
}
[data-locale=uae_gtr][data-theme=velo] span.flavour-carousel-module_title__3SvJK {
  color: #FDF351;
  font-family: "VeloWangle", sans-serif;
  font-weight: 600;
  font-size: 22px;
}
[data-locale=uae_gtr][data-theme=vuse] span.flavour-carousel-module_title__3SvJK {
  font-size: 28px;
  margin-bottom: 24px;
}
[data-locale=za] span.flavour-carousel-module_title__3SvJK {
  font-size: 23px;
  font-weight: 600;
}
[data-locale=za][data-theme=velo] span.flavour-carousel-module_title__3SvJK {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}

.flavour-carousel-module_subtitle__2Rfs5 {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 25px;
  text-transform: uppercase;
}
[data-theme=velo] .flavour-carousel-module_subtitle__2Rfs5 {
  font-size: 14px;
  line-height: 1.28;
}
[data-theme=glo] .flavour-carousel-module_subtitle__2Rfs5 {
  text-transform: initial;
}
[data-theme=glo][data-locale=de] .flavour-carousel-module_subtitle__2Rfs5, [data-theme=vype][data-locale=de] .flavour-carousel-module_subtitle__2Rfs5 {
  font-size: 14px;
}
[data-locale=za] .flavour-carousel-module_subtitle__2Rfs5 {
  font-size: 15px;
}
[data-locale=uae_gtr][data-theme=velo] .flavour-carousel-module_subtitle__2Rfs5 {
  color: #fff;
}
[data-locale=uae_gtr][data-theme=vuse] .flavour-carousel-module_subtitle__2Rfs5 {
  font-weight: 400;
  font-size: 13px;
}

.flavour-carousel-module_priceSmall__2Ac99 {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 10px;
}
[data-theme=velo] .flavour-carousel-module_priceSmall__2Ac99 {
  font-size: 14px;
  line-height: 1.28;
}
[data-theme=glo] .flavour-carousel-module_priceSmall__2Ac99 {
  text-transform: initial;
}
[data-theme=glo][data-locale=de] .flavour-carousel-module_priceSmall__2Ac99, [data-theme=vype][data-locale=de] .flavour-carousel-module_priceSmall__2Ac99 {
  font-size: 14px;
}
[data-locale=za] .flavour-carousel-module_priceSmall__2Ac99 {
  font-size: 14px;
}

.flavour-carousel-module_copy__2ogF4 {
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 30px;
}
[data-touchpoint=education] .flavour-carousel-module_copy__2ogF4 {
  font-size: 20px;
  font-weight: 400;
}
[data-theme=glo] .flavour-carousel-module_copy__2ogF4 {
  min-height: 110px;
  margin-bottom: 10px;
}
[data-theme=velo] .flavour-carousel-module_copy__2ogF4 {
  min-height: 80px;
}
[data-theme=velo][data-locale=za] .flavour-carousel-module_copy__2ogF4 {
  min-height: 140px;
  margin-bottom: 0px;
}
[data-locale=uae_gtr][data-theme=velo] .flavour-carousel-module_copy__2ogF4 {
  color: #fff;
  font-size: 16px;
  line-height: 19px;
}
[data-locale=uae_gtr][data-theme=vuse] .flavour-carousel-module_copy__2ogF4 {
  font-weight: 500;
  letter-spacing: 0.04em;
  font-size: 16px;
  line-height: 20px;
}

.flavour-carousel-module_price__2zhPl {
  text-transform: uppercase;
  font-size: 22px;
  line-height: 1;
}
[data-locale=uae_gtr][data-theme=velo] .flavour-carousel-module_price__2zhPl {
  color: #fff;
  font-size: 20px;
}
[data-locale=uae_gtr][data-theme=vuse] .flavour-carousel-module_price__2zhPl {
  font-size: 20px;
}

.flavour-carousel-module_detailsButton__1RbAV {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}

@keyframes flavour-carousel-module_slide-appear__33wsg {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes flavour-carousel-module_slide-appear-edu__5a2xQ {
  0% {
    opacity: 0;
    transform: translateX(1200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes flavour-carousel-module_slide-in-small__3WsJ9 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}